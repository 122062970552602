import { Link } from "gatsby"
import React  from "react" 
import bgImg from "../assets/images/404-bg.jpg"
import errorImg from "../assets/images/404-img.png"

const About = () => {
  
  return ( 
      <div className="error-page-wrapper" style={{backgroundImage:`url('${bgImg}')`,backgroundSize:'cover'}}>
         <div className="container">
         <div className="error-page-container">
           <h3>Look like you're lost in space</h3>
           <p>The page you're looking for does not exists..</p>
            <img src={errorImg} alt="" />
            <Link to="/" className="error-page-btn">Back To Home</Link> 
           </div>
         </div>
       </div> 
  )
}

export default About
